import { gql, useMutation, useQuery } from '@apollo/client'
import React, { useContext, useMemo } from 'react'
import { FormProvider, useForm } from "react-hook-form"

import AssetsDialog from '../../components/assets/dialog'
import AutoSave from '../../components/autosave'
import { useTitle } from '../../components/browser'
import Errors from '../../components/errors'
import Boolean from '../../components/form/boolean'
import DateInput from '../../components/form/dateinput'
import Fieldset from '../../components/form/fieldset'
import Input from '../../components/form/input'
import Metafields from '../../components/form/partials/meta-fields'
import FormSections from '../../components/form/sections'
import Slug from '../../components/form/slug'
import Textarea from '../../components/form/textarea'
import Layout from '../../components/layout'
import LayoutCompass from '../../components/layout/compass'
import LayoutContent from '../../components/layout/content'
import NotificationContext from '../../components/notifications/context'
import SectionEditingStatus from '../../components/record/editing-status'
import RecordPublishButton from '../../components/record/publish-button'
import RecordSchedule from '../../components/record/schedule'
import RecordTimestamps from '../../components/record/timestamps'
import RecordUnpublishButton from '../../components/record/unpublish-button'
import RecordsDialog from '../../components/records/dialog'
import StatusBadge from '../../components/status/badge'
import postFragment from '../../fragments/post'
import { requireAuthenticatedEditor } from '../../services/authentication'

const GET_POST_QUERY = gql`
  query GetPost($id: ID!) {
    post(id: $id) {
      ${postFragment}
    }
  }
`

const UPDATE_POST_MUTATION = gql`
  mutation UpdatePostMutation($id: ID!, $titleDe: String, $titleEn: String, $slugDe: String, $slugEn: String, $teaserDe: String, $teaserEn: String, $publishDate: ISO8601DateTime, $coverImage: ID, $metaTitleDe: String, $metaTitleEn: String, $metaDescriptionDe: String, $metaDescriptionEn: String, $keywordsDe: String, $keywordsEn: String, $series: ID, $author: ID, $advertisement: Boolean, $hidden: Boolean, $vgWortId: String ) {
    updatePost(input: { id: $id, titleDe: $titleDe, titleEn: $titleEn, slugDe: $slugDe, slugEn: $slugEn, teaserDe: $teaserDe, teaserEn: $teaserEn, publishDate: $publishDate, coverImage: $coverImage, metaTitleDe: $metaTitleDe, metaTitleEn: $metaTitleEn, metaDescriptionDe: $metaDescriptionDe, metaDescriptionEn: $metaDescriptionEn, keywordsDe: $keywordsDe, keywordsEn: $keywordsEn, series: $series, author: $author, advertisement: $advertisement, hidden: $hidden, vgWortId: $vgWortId }) {
      post {
        ${postFragment}
      }
    }
  }
`
export default function PageForm({ id }) {
  requireAuthenticatedEditor()

  const { addNotification } = useContext(NotificationContext)
  const methods = useForm()
  const { loading, error, data } = useQuery(GET_POST_QUERY,
    {
      variables: { id },
      onCompleted: (data) => methods.reset(data.post)
    }
  )
  const [mutate, { loading: mutating }] = useMutation(UPDATE_POST_MUTATION, {
    onError: () => {
      addNotification({ title: 'Error updating post', message: 'The post could not be updated.', type: 'error' })
    }
  })

  const title = useMemo(() => `${data?.post?.titleDe} - Post`, [data])
  useTitle(title)

  if (error) {
    console.error(error)
    return <div>There was an error loading the post.</div>
  }

  const onSubmit = () => { }

  return (
    <Layout>
      <LayoutCompass
        title={title}
        backTo='/posts'
      />
      <LayoutContent loading={loading}>
        <FormProvider {...methods}>
          <div className="flex justify-between space-x-6">
            <div className="bg-white rounded-lg shadow flex-1 p-6">
              <form onSubmit={methods.handleSubmit(onSubmit)} className="flex flex-col space-y-8 flex-1">
                <Errors errors={methods.formState.errors} />
                <Fieldset>
                  <Input
                    label="Title - German"
                    name="titleDe"
                    register={methods.register}
                  />
                  <Input
                    label="Title- English"
                    name="titleEn"
                    register={methods.register}
                  />
                </Fieldset>
                <Fieldset>
                  <Slug
                    label="Slug - German"
                    name="slugDe"
                    control={methods.control}
                  />
                  <Slug
                    label="Slug - English"
                    name="slugEn"
                    ccontrol={methods.control}
                  />
                </Fieldset>
                <Fieldset>
                  <DateInput
                    label="Publish Date"
                    name="publishDate"
                    control={methods.control}
                    datetime
                  />
                </Fieldset>
                <Fieldset>
                  <Textarea
                    label="Teaser - German"
                    name="teaserDe"
                    control={methods.control}
                  />
                  <Textarea
                    label="Teaser - English"
                    name="teaserEn"
                    control={methods.control}
                  />
                </Fieldset>
                <Fieldset>
                  <AssetsDialog
                    label='Cover Image'
                    value={data?.post?.coverImage}
                    methods={methods}
                    name="coverImage.id"
                  />
                </Fieldset>
                <Fieldset>
                  <Boolean
                    label="Advertisement"
                    name="advertisement"
                    register={methods.register}
                  />
                </Fieldset>
                <Fieldset>
                  <RecordsDialog
                    label='Series'
                    value={data?.post?.series}
                    methods={methods}
                    name="series.id"
                    emptyLabel="No series selected."
                    query={`
                      query GetSeries($search: String) {
                        records: allSeries(first: 200, search: $search) {
                          edges {
                            node {
                              __typename
                              id
                              titleDe
                              editingStatus
                            }
                          }
                        }
                      }
                    `}
                  />
                </Fieldset>
                <Fieldset>
                  <RecordsDialog
                    label='Author'
                    value={data?.post?.author}
                    methods={methods}
                    name="author.id"
                    emptyLabel="No author selected."
                    query={`
                      query GetAuthors($search: String) {
                        records: allEditors(first: 200, search: $search) {
                          edges {
                            node {
                              __typename
                              id
                              titleDe: displayName
                            }
                          }
                        }
                      }
                    `}
                  />
                </Fieldset>
                <Fieldset>
                  <FormSections
                    recordId={data?.post?.id}
                    sections={data?.post?.sections}
                  />
                </Fieldset>
                <Metafields methods={methods} />
                <Fieldset>
                  <Input
                    label="VG Wort Id"
                    name="vgWortId"
                    register={methods.register}
                  />
                </Fieldset>
                <Fieldset>
                  <Boolean
                    label="Hidden"
                    name="hidden"
                    register={methods.register}
                    help="If checked, the post will not be visible on the website."
                  />
                </Fieldset>
              </form>
            </div>
            <AutoSave
              id={id}
              mutate={mutate}
              control={methods.control}
            />
            <div className="w-3/12 bg-white shadow rounded-lg sticky top-24 self-start space-y-4 p-6 text-gray-500">
              <div className="flex justify-between items-center">
                <div className="font-bold">Status</div>
                <StatusBadge status={data?.post?.editingStatus} />
              </div>
              <RecordPublishButton
                record={data?.post}
                label="post"
                methods={methods}
              />
              <RecordUnpublishButton
                record={data?.post}
                label="post"
                methods={methods}
              />
              <SectionEditingStatus
                isDirty={methods.formState.isDirty}
                mutating={mutating}
              />
              <RecordSchedule
                record={data?.post}
                label="post"
                methods={methods}
              />
              <RecordTimestamps
                record={data?.post}
              />
            </div>
          </div>
        </FormProvider>
      </LayoutContent>
    </Layout>
  )
}
